
// Webpack imports
// Global var to use it across components
import 'materialize-css/dist/js/materialize.min.js';
import Masonry from 'masonry-layout';
//import Chocolat from 'chocolat';
import venobox from 'venobox';
import 'materialize-css/dist/css/materialize.min.css';
// import 'chocolat/dist/css/chocolat.css';
import '../scss/styles.scss';

// Class and Function Imports
import Main from './template.main';

// Class intialisation
let main = new Main();

// JQuery $(document).ready function 
$(function() {

    //$('.tabs').tabs();
    // var elem = document.querySelector('.tabs');
    // var options = {};
    // var instance = M.Tabs.init(elem, options);

    main.checkForTransparentNavbar().debounce();

    $(document).ready(function () {
        $('.parallax').parallax();
		$('.scrollspy').scrollSpy();
		
		//$(".materialboxed").materialbox();
		var elems = document.querySelectorAll(".materialboxed");
		var options = {
			onOpenStart: function(){
				$(".article-section").css({ position: "inherit" });
			},
			onOpenEnd: function(){
				$(".grid-item img").css({ marginTop: "0px", marginLeft: "0px" });
				$(".grid-item--width2 img").css({ marginTop: "0px", marginLeft: "0px" });
				$(".grid-item--width4 img").css({ marginTop: "0px" });
			},
			onCloseEnd: function(){
				$(".article-section").css({ position: "relative" });
			}
		};
    	var instances = M.Materialbox.init(elems, options);

        var sideNav = $('.sidenav').sidenav({"edge": "right"});
        $('#sidenav-close').click(function(){
            $('.sidenav').sidenav('close')
        })
        //$('#sub-nav-aside').css({"opacity":"1.0"});
		//main.checkForSubNavAsidePosition('init');;

		const grid = document.querySelector('.grid');
		const msnry = new Masonry(grid, {
			// options
			//columnWidth: 256,
			itemSelector: '.grid-item',

			columnWidth: '.grid-sizer',
			//percentPosition: true
		});

		msnry.once('layoutComplete', () => {
			grid.classList.add('load');
			grid.style.visibility = "visible";
		})

		msnry.layout();

    });

    $(window).on('scroll', function () {
        main.checkForTransparentNavbar().debounce();
        //main.checkForSubNavAsidePosition('scroll');
    });

    $(window).on('resize', function () {
        //main.checkForSubNavAsidePosition('resize');
	});

    console.log('jquery is ready');

});